<template>
  <div class="booking-body" v-if="singledriverData != null">
    <div class="booking-fold">
      <div class="booking-flow">
        <div class="bf-body">
          <div class="bf-profile">
            <div class="bpf-top" style="padding: 30px 63px 0px 63px !important">
              <div class="bf-header" style="margin-bottom:15px;">
                <a role="button" @click.prevent="$emit('onClose')" class="back-link">
                  <figure>
                    <img :src="require('@assets/images/back-circle-arrow.png')" alt="" />
                  </figure>
                  Back to map
                </a>
              </div>
              <h4 class="dprofile">Driver Profile</h4>
              <div class="profile-info">
                <figure>
                  <avatar
                    class="w-100 h-100"
                    v-if="singledriverData.image_url == null"
                    :username="singledriverData.full_name"
                  ></avatar>
                  <img
                    v-if="singledriverData.image_url != null"
                    :src="singledriverData.image_url"
                    alt=""
                  />
                </figure>
                <div class="profile-des">
                  <h5>{{ singledriverData.full_name }}</h5>
                  <div class="status">
                    <span v-if="singledriverData.user_has_one_current_location">
                      Last Active: {{
                        moment(singledriverData.user_has_one_current_location.created_at).fromNow()
                      }}
                    </span>
                    <span v-if="singledriverData.last_live_location_suburb">
                        Last seen in: {{ singledriverData.last_live_location_suburb }}
                      </span>
                  </div>
                  <p>
                    {{ singledriverData.profile_description }}
                  </p>
                  <div class="bf-other-info">
                    <div class="bfo-left">
                      <div class="vehicle">
                        <h4>VEHICLES</h4>
                        <div class="vehicl-list">
                          <div
                            class="vehicle-img"
                            v-for="v_detail in singledriverData.driver_vehicles_detail"
                            v-bind:key="v_detail.id"
                          >
                            <figure>
                              <img :src="v_detail.image_url" alt="" />
                            </figure>
                            <span>{{ v_detail.name }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="speciality">
                        <h4>Languages :</h4>
                        <p>Freight Items, Interstate Delivery</p>
                      </div>
                      <div class="reviews">
                        <h4>Rating</h4>
                        <div class="rating d-flex">
                          <star-rating
                            :star-size="16"
                            :increment="0.50"
                            :rating="singledriverData.user_driver_rating_review_avg"
                            read-only
                            :show-rating="false"
                            active-color="#00A6E3"
                          ></star-rating>
                          <span>
                            {{Number(singledriverData.user_driver_rating_review_avg)}}/5
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="bfo-right">
                      <!-- <div class="bfor-price">
                        price:<span>${{ singledriverData.bid_amount }}</span>
                      </div> -->

                      <Verification
                        :verification-data="singledriverData.driver_documents_verified_or_not"
                      />
                    </div>
                  </div>
                  <div>
                    <router-link :to="{ name: 'message' }" tag="button" class="btn btn-msg">
                      <figure><img :src="require('@assets/images/message.svg')" alt="" /></figure>
                      Message
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="bpf-bottom">
              <div
                class="bf-work-info"
                v-if="singledriverData.user_has_many_work_history.length > 0"
              >
                <h4>Work History</h4>
                <ul>
                  <li
                    v-for="history in singledriverData.user_has_many_work_history"
                    v-bind:key="history.id"
                  >
                    <h3>
                      {{ history.title }} {{ history.start_date_year }}
                      <span v-if="history.end_date_year != null">-</span>
                      {{ history.end_date_year }}
                    </h3>
                    <p>
                      {{ history.description }}
                    </p>
                  </li>
                </ul>
              </div>
              <div class="bf-reviews" v-if="singledriverData.user_rating_review.length > 0">
                <h4>Reviews</h4>
                <div
                  class="review-list"
                  v-for="review in singledriverData.user_rating_review"
                  v-bind:key="review.id"
                >
                  <div class="rating d-flex">
                    <star-rating
                      :star-size="16"
                      :increment="0.50"
                      :rating="parseFloat(review.rating)"
                      read-only
                      :show-rating="false"
                      active-color="#00A6E3"
                    ></star-rating>
                    <span class="ml-2">{{ review.rating }}/5</span>
                  </div>
                  <p>
                    {{ review.review }}
                  </p>
                  <label>
                    {{ review.given_user_detail ? `-${review.given_user_detail.full_name}` : '' }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Verification from '@/views/pages/DriverComponents/Verification.vue';
import Avatar from 'vue-avatar';
import StarRating from 'vue-star-rating';
import moment from 'moment';

export default {
  name: 'DriverShow',
  components: { StarRating, Verification, Avatar },
  props: ['singledriverData'],
  methods: {
    moment,
  },
};

</script>

<style></style>
