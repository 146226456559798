<template>
   <div class="booking-main search-result-main">
    <div class="booking-body">
      <div class="dashboard-header booking-header">
        <form class="" method="post" @submit.prevent="onSubmit">
          <div class="bh-form">
            <div class="form-group selection">
              <v-select
                @search="getSuburb1"
                @input ="selectSuburb(1)"
                v-model="serverParams.suburb_option"
                :options="suburb_option"
                label="place_name"
                placeholder="Search for a location"
              >
                <span slot="no-options">
                  {{ noOptionMessage }}
                </span>
                <template #selected-option="{ place_name , state_name }">
                  {{ place_name }} , {{ state_name }}
                </template>
                <template #option="{ place_name , state_name }">
                  <h6 style="margin: 0">{{ place_name }} ,
                    {{ state_name }}</h6>
                </template>
              </v-select>
            </div>
            <!-- <div class="form-group selection">
              <select
                class="form-select"
                v-model="serverParams.suburb_option"
                @change="selectSuburb(0)"
              >
                <option value="">Suburb</option>
                <option
                  v-for="(suburb,index) in suburb_option"
                  v-bind:key="index"
                  v-bind:value="index"
                >
                  {{ suburb.place_name }} - {{ suburb.state_name }}
                </option>
              </select>
            </div> -->
            <div class="form-group selection">
              <select
                class="form-select"
                v-model="serverParams.vehicle_id1"
              >
                <option value="null" hidden>Choose vehicle</option>
                <option
                  v-for="option in vehicle_option"
                  v-bind:key="option.id"
                  v-bind:value="option.id"
                >
                  {{ option.name }}
                </option>
              </select>
            </div>
            <!-- <div class="form-group selection">
              <select class="form-select" v-model="serverParams.search_by_rating">
                <option selected value="null">Choose Rating</option>
                <option value="5">5 star</option>
                <option value="4">4 star</option>
                <option value="3">3 star</option>
                <option value="2">2 star</option>
                <option value="1">1 star</option>
              </select>
            </div>
            <div class="form-group selection">
              <select class="form-select" v-model="serverParams.search_by_verification">
                <option selected value="">Driver Verification Type</option>
                <option value="Verified">Verified</option>
                <option value="Verifed Now">Unverified</option>
              </select>
            </div> -->
            <button
              type="submit"
              class="btn btn-primary"
            >
              Search
            </button>
          </div>
        </form>
      </div>
      <div class="booking-fold d-flex">
        <div class="booking-table table-responsive"  style="width:50%">
          <table class="table">
            <thead>
              <tr>
                <!-- <th>No</th> -->
                <th>
                  <div
                    class="option-heading"
                    :class="{
                      active:
                        serverParams.orderByField == 'name' &&
                        serverParams.orderByType == 'asc',
                    }"
                    @click="sortDriver('name')"
                  >
                    Name
                  </div>
                </th>
                <!-- <th>Email</th> -->
                <th>
                  <div
                    class="option-heading"
                    :class="{
                      active:
                        serverParams.orderByField == 'user_driver_rating_review_avg' &&
                        serverParams.orderByType == 'asc',
                    }"
                    @click="sortDriver('user_driver_rating_review_avg')"
                  >
                    Rating
                  </div>
                </th>
                <th>Vehicle</th>
                <!-- <th>Verification</th> -->
                <th>Status</th>
              </tr>
            </thead>
            <tbody v-if="drivers.length > 0 && !isSearchSubmit">
                <tr
                  v-for="(driver, index) in drivers"
                  v-bind:key="index"
                  style="cursor:pointer"
                  @click="
                    driver.user_has_one_current_location
                      ? recenter(
                          driver.user_has_one_current_location.latitude,
                          driver.user_has_one_current_location.longitude,
                          driver
                        )
                      : ((driver.latitude && driver.longitude) ?
                          recenter(driver.latitude, driver.longitude, driver) : noLocationFound());
                    showDriver = 0;
                    currentDriverId = driver.id;
                  "
                  :class="currentDriverId == driver.id ? 'nth-child-border' :  '' "
                >
                  <!-- <td>
                    {{ serverParams.currentPage * serverParams.perPage -
                       serverParams.perPage + index + 1 }}
                  </td> -->
                  <td>{{ driver.full_name }}</td>
                  <!-- <td>{{ driver.email }}</td> -->
                  <td>{{ Number(driver.user_driver_rating_review_avg)}}/5</td>
                  <td>
                      <div v-for="v_detail in driver.driver_vehicles_detail"
                        v-bind:key="v_detail.id">
                        <span>{{ v_detail.name }}</span>
                      </div>
                  </td>
                  <!-- <td>{{ driver.driver_is_verify}}</td> -->
                  <td>{{ driver.status_name}}</td>
                </tr>
            </tbody>
            <tbody v-if="isSearchSubmit">
              <tr>
                <td colspan="100%" style="text-align: center">
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-if="drivers.length == 0 && !isSearchSubmit">
              <tr>
                <td colspan="100%" style="text-align: center">
                    <img
                      class="noDataImage"
                      :src="require('@/assets/images/nodata.svg')"
                      alt=""
                    /><br /><br />
                    <b>No Driver found</b>
                </td>
              </tr>
            </tbody>
          </table>
          <pagination
            v-if="drivers.length > 0 && !isSearchSubmit"
            :perPage="serverParams.perPage"
            :totalRows="tableInfo.total"
            :value="serverParams.page"
            v-on:pagechanged="pagechanged"
            v-on:perPagechanged="perPagechanged"
          ></pagination>
        </div>
        <div class="map-flow"  v-if="showDriver != 1" id="map_canvas"
        style="width: 50%; position: sticky; top: 0;"
        v-bind:style="{ height: setheight + 'px' }">
          <gmap-map :center="center" :zoom="4" style="width: 100%; height: 900px"
          v-bind:style="{ height: setheight + 'px' }">
            <GmapCluster>
              <gmap-marker
                :key="index"
                v-for="(gmp, index) in locations"
                :position="gmp"
                :icon="
                  gmp.driver.driver_current_vehicles_detail
                    ? getMarkerImage(gmp.driver.driver_current_vehicles_detail.image_url)
                    : ''
                "
                @mouseover="openWindow(gmp)"
              ></gmap-marker>
            </GmapCluster>
            <gmap-info-window
              @closeclick="window_open = false"
              :opened="window_open"
              :position="infowindow"
              :options="{
                pixelOffset: {
                  width: 0,
                  height: -30,
                },
              }"
            >
            <div v-html="infoContent" @click="handleClick"
            @mouseleave="closeWindow"></div>
            </gmap-info-window>
          </gmap-map>
        </div>
        <div class="map-flow" v-if="showDriver == 1" style="width: 50%; height: 855px">
          <DriverShow :singledriverData="singledriverData" @onClose="showDriver = 0" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

/* eslint-disable no-unused-vars */

import pagination from '@/components/pagination.vue';
import DriverShow from '@/views/pages/DriverComponents/DriverShow.vue';
import GmapCluster from 'vue2-google-maps/dist/components/cluster';
import Avatar from 'vue-avatar';
import verifyImage from '../../../assets/images/verify.png';

export default {
  name: 'SearchResult',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    pagination, DriverShow, Avatar, GmapCluster,
  },
  data() {
    return {
      rows: [],
      vehicle_option: [],
      suburb_option: [],
      tableInfo: {},
      totalRecords: 0,
      isSearchSubmit: false,
      noOptionMessage: 'Please enter minimum 3 characters for search',
      serverParams: {
        search: '',
        suburb_option: '',
        vehicle_id1: null,
        search_by_rating: null,
        search_by_verification: '',
        currentPage: 1,
        page: 1,
        perPage: 10,
        latitude: '',
        longitude: '',
      },
      center: {
        lat: 0,
        lng: 0,
      },
      locations: [],
      currentLocation: null,
      info_marker: null,
      infowindow: { lat: 0, lng: 0 },
      window_open: false,
      infoContent: '',
      resized: false,
      showDriver: 0,
      singledriverData: null,
      currentDriverId: 0,
      setheight: window.innerHeight,
    };
  },
  watch: {},
  computed: {
    drivers() {
      return this.rows;
      // const start
      // = this.serverParams.page * this.serverParams.perPage - this.serverParams.perPage;
      // const end = start + this.serverParams.perPage - 1;

      // return this.rows.filter((row, index) => index >= start && index <= end);
    },
  },
  created() {
    this.getVehicles();
    if (this.$route.params.tag) {
      this.serverParams = this.$route.params.tag;
    }
    if (this.serverParams.search) {
      this.getSuburb();
    }
    // if (!this.serverParams.suburb_option) {
    //   this.serverParams.suburb_option = 'Search Suburb';
    // }
    this.onSubmit();
    setInterval(() => {
      if (this.showDriver === 0 && this.$route.name === 'map') {
        this.onSubmit();
      }
    }, 180000);
  },
  methods: {
    getMarkerImage(url) {
      return {
        url,
        scaledSize: {
          width: 32,
          height: 32,
          f: 'px',
          b: 'px',
        },
      };
    },
    recenter(latitude, longitude, driver) {
      this.center.lat = parseFloat(latitude);
      this.center.lng = parseFloat(longitude);
      const tempObj = { lat: parseFloat(latitude), lng: parseFloat(longitude), driver };
      this.openWindow(tempObj);
    },
    checkDriverData() {
      const rowData = JSON.parse(JSON.stringify(this.rows));
      if (Array.isArray(rowData) && rowData.length === 0) {
        this.locations = [
          {
            driver: '',
            label: 'Aus',
            lat: parseFloat(this.serverParams.latitude),
            lng: parseFloat(this.serverParams.longitude),
          },
        ];
        this.$nextTick(() => {
          setTimeout(() => {
            this.center.lat = parseFloat(this.serverParams.latitude);
            this.center.lng = parseFloat(this.serverParams.longitude);
          }, 0);
        });
      }
    },
    noLocationFound() {
      this.currentDriverId = 0;
      this.$toast.error('No location found for this driver');
    },
    openWindow(gmp) {
      this.infowindow.lat = gmp.lat;
      this.infowindow.lng = gmp.lng;
      this.infoContent = this.getInfoWindowContent(gmp.driver);
      this.window_open = true;
    },
    closeWindow() {
      this.infowindow.lat = 0;
      this.infowindow.lng = 0;
      this.window_open = false;
    },
    getInfoWindowContent(gmp) {
      let markerDetails = '';
      markerDetails += `<div class="driver-hover" style="position: unset">
                          <div class="status">Active 4h ago</div>
                          <div class="di-wrapper">
                            <div class="driver-img">
                              <figure>`;

      if (gmp.image_url == null) {
        markerDetails += `<div
                          aria-hidden="true"
                          class="vue-avatar--wrapper w-100 h-100"
                          style="
                            display: flex;
                            width: 50px;
                            height: 50px;
                            border-radius: 50%;
                            font: 20px / 50px Helvetica, Arial, sans-serif;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            user-select: none;
                            background-color: rgb(255, 152, 0);
                            color: rgb(255, 232, 80);
                          "
                        >
                          <!---->
                          <span>${gmp.name[0].toUpperCase()}</span>
                        </div>`;
      } else {
        markerDetails += `<img src="${gmp.image_url}" alt="" />`;
      }

      markerDetails += `</figure>
                        <span class="verify">
                          <img src="${verifyImage}"/>
                        </span>
                      </div>
                      <div class="dr-right">
                        <div class="driver-info">
                          <h4>${gmp.full_name ? gmp.full_name : ''}</h4>
                          <span>${gmp.address ? gmp.address : ''}</span>
                        </div>
                        <div class="dv-info">
                          <div class="dv-name">${gmp.driver_current_vehicles_detail ? gmp.driver_current_vehicles_detail.name : ''}</div>
                          <div class="rating">
                            ${Number(gmp.user_driver_rating_review_avg)}/5<i class="fas fa-star"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button type="button" class="btn btn-see-more" data-driverid="${gmp.id}">
                      See More
                    </button>
                  </div>`;
      return markerDetails;
    },
    handleClick(e) {
      if (e.target.matches('.btn-see-more')) {
        const driverId = e.target.getAttribute('data-driverid');
        this.showDriverProfile(driverId);
      }
    },
    setLocationLatLng() {
      navigator.geolocation.getCurrentPosition((geolocation) => {
        this.center = {
          lat: geolocation.coords.latitude,
          lng: geolocation.coords.longitude,
        };
      });
      const arrayFailed = Object.entries(this.drivers).map((arr) => ({
        lat:
          arr[1].user_has_one_current_location && arr[1].user_has_one_current_location.latitude
            ? parseFloat(arr[1].user_has_one_current_location.latitude)
            : parseFloat(arr[1].latitude),
        lng:
          arr[1].user_has_one_current_location && arr[1].user_has_one_current_location.longitude
            ? parseFloat(arr[1].user_has_one_current_location.longitude)
            : parseFloat(arr[1].longitude),
        driver: arr[1],
        label: 'Rajkot',
      }));

      this.locations = arrayFailed;
    },
    getVehicles() {
      this.$http
        .get('/getVehicalTypes')
        .then((res) => {
          this.vehicle_option = res.data.data;
        })
        .catch((error) => {
          this.$toast.error('Vehicle not found');
        })
        .finally(() => {});
    },
    // eslint-disable-next-line no-unused-vars
    getSuburb1(search, loading) {
      this.serverParams.search = search;
      if (this.serverParams.search.length > 2) {
        this.noOptionMessage = 'Sorry, no matching options';
        this.getSuburb();
      }
      if (this.serverParams.search.length <= 2) {
        this.noOptionMessage = 'Please enter minimum 3 characters for search';
        this.suburb_option = [];
      }
    },
    /* eslint-disable */
    getSuburb() {
      this.$http
        .get('/getSuburbs', { params: { ...this.serverParams } })
        .then((res) => {
          this.suburb_option = res.data.data;
        })
        .catch((error) => {
          this.$toast.error('Suburb not found');
        })
        .finally(() => {});
    },
    onSubmit() {
      this.isSearchSubmit = true;
      this.showDriver = 0;
      // this.serverParams.currentPage = 1;
      // this.serverParams.page = 1;
      // this.serverParams.perPage = 10;
      if (this.serverParams.vehicle_id1 > 0) {
        this.serverParams.vehicle_id = Array.of(this.serverParams.vehicle_id1);
      } else {
        delete this.serverParams.vehicle_id;
      }
      this.$http
        .get('/advanceSearch', {
          params: {
            ...this.serverParams,
          },
        })
        .then((res) => {
          const { collection, ...info } = res.data.data;
          this.rows = collection;
          this.tableInfo = info;
          this.showDriver = 0;
          this.center.lat = (collection.length > 0 && collection[0].latitude)
            ? parseFloat(collection[0].latitude)
            : parseFloat(JSON.parse(localStorage.getItem('userData')).latitude ?? 0);
          this.center.lng = (collection.length > 0 && collection[0].longitude)
            ? parseFloat(collection[0].longitude)
            : parseFloat(JSON.parse(localStorage.getItem('userData')).longitude ?? 0);
          this.setLocationLatLng();
        })
        .catch((error) => {
          this.rows = [];
          this.$toast.error(error.response.data.message);
        })
        .finally(() => {
          this.isSearchSubmit = false;
          this.checkDriverData();
        });
    },
    showDriverProfile(driverId) {
      this.isFetchingBooking = true;
      this.$http
        .get(`customer/map/driver/show/${driverId}`)
        .then((res) => {
          this.singledriverData = res.data.data;
          this.$nextTick(() => {
            this.showDriver = 1;
          });
        })
        .catch((error) => {
          this.singledriverData = null;
          this.$toast.error('Driver not found');
        })
        .finally(() => {
          this.isFetchingBooking = false;
        });
    },
    /* eslint-disable */
    selectSuburb(type) {
      if (type === 0) {
        if (this.serverParams.suburb_option) {
          this.serverParams.latitude = this.suburb_option[this.serverParams.suburb_option].latitude;
          this.serverParams.longitude = this.suburb_option[this.serverParams.suburb_option].longitude;
        } else {
          delete this.serverParams.latitude;
          delete this.serverParams.longitude;
        }
      }
      if (type === 1) {
        const isSubFind = this.suburb_option.findIndex((x) => x === this.serverParams.suburb_option);
        if (isSubFind > -1) {
          this.serverParams.latitude = this.serverParams.suburb_option.latitude;
          this.serverParams.longitude = this.serverParams.suburb_option.longitude;
        } else {
          delete this.serverParams.latitude;
          delete this.serverParams.longitude;
        }
      }
    },
    pagechanged(e) {
      this.serverParams.page = Number(e);
      this.serverParams.currentPage = Number(e);
      this.onSubmit();
    },
    perPagechanged(e) {
      this.serverParams.perPage = Number(e);
      this.onSubmit();
    },
    sortDriver(Field){
      if (this.serverParams.hasOwnProperty('orderByField')) {
        if (this.serverParams.orderByField === Field) {
          this.updateParams({
            orderByField: Field,
            orderByType: this.serverParams.orderByType === 'asc' ? 'desc' : 'asc',
          });
        } else {
          this.updateParams({
            orderByField: Field,
            orderByType: this.serverParams.orderByType === 'asc' ? 'desc' : 'asc',
          });
        }
      } else {
        this.updateParams({
          orderByField: Field,
          orderByType: 'asc',
        });
      }
      this.sortedArray();
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    sortedArray(){
      // return this.rows.sort((a, b) => {
      //   return b.name.localeCompare(a.name);
      // });
      return this.rows.sort((p1,p2) => {
          let modifier = 1;
          if(this.serverParams.orderByType === 'desc') modifier = -1;

          if(this.serverParams.orderByField == 'name'){
            if(p1['name'].toLowerCase() < p2['name'].toLowerCase()) return -1 * modifier;
            if(p1['name'].toLowerCase() > p2['name'].toLowerCase()) return 1 * modifier;
          }else{
            if(p1[this.serverParams.orderByField] < p2[this.serverParams.orderByField]) return -1 * modifier;
            if(p1[this.serverParams.orderByField] > p2[this.serverParams.orderByField]) return 1 * modifier;
          }
          return 0;
      });
    }
  },
};
</script>
<style scoped>
.search-spinner.spinner-border{
    height: 1rem !important;
    width:  1rem !important;
}
#map_canvas {
  background-image: url('~@/assets/images/Spinner-1s-151px.gif');
  background-repeat: no-repeat;
  background-position: center;
}
.active {
  display: block;
}
.option-heading {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.option-heading:after {
  content: '';
  position: absolute;
  display: block;
  width: 10px;
  height: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  top: 6px;
  right: -15px;
  background-image: url('~@assets/images/triangle.svg');
}
.option-heading.active:after {
  transform: rotate(180deg);
}
.nth-child-border {
   border-bottom: 2px solid #00a6e3 !important;
   border-top: 2px solid #00a6e3 !important;
 }
</style>
